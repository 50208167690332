.footer {
  $color: rgba($white, 0.5);

  height: $footer-height-mobile;
  @include breakpoint($tablet) { height: $footer-height-tablet; }
  @include breakpoint($screen) { height: $footer-height-screen; }

  background-color: $brown;

  .footer-inner,
  .footer-bottom {
    @include container;

    margin-left: 1em;
    margin-right: 1em;

    @include breakpoint($screen) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .footer-bottom {

    hr {
      @include span(full);
      border-color: $lightbrown;
      border-width: 2px;
    }

    .footer-bottom-navigation {
      float: left;
      margin-left: 0;

      li {
        list-style-type: none;
      }

      a {
        color: $color;
      }
    }

    .copyright {
      color: $color;
      float: right;
    }
  }

  .footer-inner {
    padding-top: emRhythm(1*4);

    address {
      @include setType(1*4, 1em);
      color: $color;

      @include gallery(6 of 12);
      margin-bottom: emRhythm(1*4);

      @include breakpoint($tablet) {
        @include gallery(4 of 12);
      }

      @include breakpoint($screen) {
        @include gallery(3 of 12);
      }

      a {
        color: $color;
        text-decoration: underline;
      }
    }
  }
}
