@charset "UTF-8";
@import url(../components/reset-css/reset.css);
@import url(../components/flickity/dist/flickity.min.css);
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  height: 100%; }

body {
  background-color: #A9DA6F; }

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-family: "walsheim-ultra-light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-ultra-light-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-ultra-light-web.woff") format("woff"), url("../fonts/original/gt-walsheim-ultra-light-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-ultra-light-web.svg#walsheim-ultra-light") format("svg"); }

@font-face {
  font-family: "walsheim-ultra-light-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-ultra-light-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-ultra-light-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-ultra-light-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-ultra-light-oblique-web.svg#walsheim-ultra-light-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-thin";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-thin-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-thin-web.woff") format("woff"), url("../fonts/original/gt-walsheim-thin-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-thin-web.svg#walsheim-thin") format("svg"); }

@font-face {
  font-family: "walsheim-thin-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-thin-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-thin-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-thin-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-thin-oblique-web.svg#walsheim-thin-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-light-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-light-web.woff") format("woff"), url("../fonts/original/gt-walsheim-light-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-light-web.svg#walsheim-light") format("svg"); }

@font-face {
  font-family: "walsheim-light-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-light-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-light-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-light-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-light-oblique-web.svg#walsheim-light-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-regular-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-regular-web.woff") format("woff"), url("../fonts/original/gt-walsheim-regular-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-regular-web.svg#walsheim-regular") format("svg"); }

@font-face {
  font-family: "walsheim-regular-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-regular-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-regular-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-regular-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-regular-oblique-web.svg#walsheim-regular-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-medium-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-medium-web.woff") format("woff"), url("../fonts/original/gt-walsheim-medium-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-medium-web.svg#walsheim-medium") format("svg"); }

@font-face {
  font-family: "walsheim-medium-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-medium-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-medium-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-medium-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-medium-oblique-web.svg#walsheim-medium-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-bold-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-bold-web.woff") format("woff"), url("../fonts/original/gt-walsheim-bold-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-bold-web.svg#walsheim-bold") format("svg"); }

@font-face {
  font-family: "walsheim-bold-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-bold-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-bold-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-bold-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-bold-oblique-web.svg#walsheim-bold-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-black";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-black-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-black-web.woff") format("woff"), url("../fonts/original/gt-walsheim-black-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-black-web.svg#walsheim-black") format("svg"); }

@font-face {
  font-family: "walsheim-black-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-black-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-black-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-black-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-black-oblique-web.svg#walsheim-black-oblique") format("svg"); }

@font-face {
  font-family: "walsheim-ultra-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-ultra-bold-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-ultra-bold-web.woff") format("woff"), url("../fonts/original/gt-walsheim-ultra-bold-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-ultra-bold-web.svg#walsheim-ultra-bold") format("svg"); }

@font-face {
  font-family: "walsheim-ultra-bold-oblique";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/original/gt-walsheim-ultra-bold-oblique-web.eot?#iefix") format("embedded-opentype"), url("../fonts/original/gt-walsheim-ultra-bold-oblique-web.woff") format("woff"), url("../fonts/original/gt-walsheim-ultra-bold-oblique-web.ttf") format("truetype"), url("../fonts/original/gt-walsheim-ultra-bold-oblique-web.svg#walsheim-ultra-bold-oblique") format("svg"); }

html {
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  line-height: 1.5; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "walsheim-regular", "Georgia", "Times New Roman", serif;
  font-weight: 400;
  color: #000; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: "walsheim-light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold; }

h1, .h1 {
  margin-bottom: 0.18756em;
  font-size: 1.9994em;
  line-height: 1.12534; }
  @media (min-width: 43.75em) {
    h1, .h1 {
      margin-bottom: 0.13264em;
      font-size: 2.82715em;
      line-height: 1.06114; } }
  @media (min-width: 56.25em) {
    h1, .h1 {
      margin-bottom: 0.09381em;
      font-size: 3.99758em;
      line-height: 0.93807; } }

h2, .h2 {
  margin-bottom: 0.26521em;
  font-size: 1.414em;
  line-height: 1.32603; }
  @media (min-width: 43.75em) {
    h2, .h2 {
      margin-bottom: 0.18756em;
      font-size: 1.9994em;
      line-height: 1.12534; } }
  @media (min-width: 56.25em) {
    h2, .h2 {
      margin-bottom: 0.13264em;
      font-size: 2.82715em;
      line-height: 1.06114; } }

h3, .h3 {
  margin-bottom: 0.26521em;
  font-size: 1.414em;
  line-height: 1.32603; }
  @media (min-width: 56.25em) {
    h3, .h3 {
      margin-bottom: 0.18756em;
      font-size: 1.9994em;
      line-height: 1.12534; } }

h4, .h4 {
  margin-bottom: 0.26521em;
  font-size: 1.414em;
  line-height: 1.32603; }

h5, .h5,
h6, .h6 {
  margin-bottom: 0.375em;
  font-size: 1em;
  line-height: 1.5; }

p {
  margin-top: 0;
  margin-bottom: 1.5em;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 36em;
  -webkit-hyphens: auto;
  -epub-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word; }

p + p {
  margin-top: -1.5em;
  text-indent: 1.5em; }

small,
.font-small {
  font-size: 0.70721em;
  line-height: 1.59075; }

em {
  font-style: italic; }

strong {
  font-weight: bold; }

blockquote {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.5em;
  border-left: 6px solid gray;
  font-family: "walsheim-regular", "Georgia", "Times New Roman", serif; }
  blockquote p {
    margin-bottom: 0.75em; }
  blockquote footer cite:before {
    content: " – "; }

pre,
code {
  font-family: "Lucida Console", Monaco, monospace; }

time,
.time {
  text-transform: uppercase; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.5em;
  margin-left: 1.5em;
  padding-top: 0;
  padding-bottom: 0; }

ul ul,
ol ol,
dl dl {
  margin-left: 0; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

abbr {
  font-variant: small-caps;
  font-weight: 600;
  text-transform: lowercase; }

abbr[title]:hover {
  cursor: help; }

hr {
  margin-top: 1.5em;
  margin-bottom: 0;
  border: none;
  border-top-color: #000;
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 1.4375em; }

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-smooth: always !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004) !important;
  font-style: normal;
  font-weight: normal; }

::selection {
  background: #A9DA6F;
  color: #402E2A;
  -webkit-text-stroke-width: 0; }

::-moz-selection {
  background: #A9DA6F;
  color: #402E2A; }

h1 {
  font-size: 1.9994em;
  line-height: 1.12534;
  color: #684551;
  font-family: "walsheim-bold";
  text-transform: uppercase; }
  @media (min-width: 42em) {
    h1 {
      font-size: 2.82715em;
      line-height: 1.06114; } }
  @media (min-width: 62em) {
    h1 {
      font-size: 3.99758em;
      line-height: 1.12568;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      transform: rotate(-3deg);
      color: #684551; } }

h2 {
  font-family: "walsheim-light";
  font-size: 1.414em;
  line-height: 1.32603;
  color: #FFF;
  margin-bottom: 0.75em;
  letter-spacing: 0px; }

h3 {
  font-family: "walsheim-regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 0; }

h4 {
  font-family: "walsheim-regular";
  font-size: 1em;
  line-height: 1.5; }

a {
  text-decoration: none;
  color: white; }

p {
  hyphens: none; }

.text a {
  text-decoration: underline;
  color: #000; }

.page {
  background-color: #F7F7F7;
  min-height: 100%;
  margin-bottom: -39em; }
  @media (min-width: 42em) {
    .page {
      margin-bottom: -31.5em; } }
  @media (min-width: 62em) {
    .page {
      margin-bottom: -24em; } }
  .page:after {
    content: "";
    display: block;
    height: 39em; }
    @media (min-width: 42em) {
      .page:after {
        height: 31.5em; } }
    @media (min-width: 62em) {
      .page:after {
        height: 24em; } }

.header {
  background-color: #A9DA6F;
  width: 100%;
  height: 6em; }
  .header .header-inner {
    max-width: 60em;
    margin-left: auto;
    margin-right: auto; }
    .header .header-inner:after {
      content: " ";
      display: block;
      clear: both; }

.teaser {
  position: relative;
  width: 100%;
  height: 24em;
  background-color: #684551;
  overflow: hidden; }
  @media (min-width: 42em) {
    .teaser {
      height: 24em; } }
  @media (min-width: 62em) {
    .teaser {
      height: 30em; } }
  @media (min-width: 120em) {
    .teaser {
      height: 54em; } }
  .teaser div.carousel-image {
    transition: opacity 0.4s;
    opacity: 0;
    margin: 0 auto;
    width: 100%;
    height: 24em;
    overflow: hidden;
    background-size: cover;
    background-position: center center; }
    .teaser div.carousel-image.flickity-bg-lazyloaded, .teaser div.carousel-image.flickity-bg-lazyerror {
      opacity: 1; }
    @media (min-width: 42em) {
      .teaser div.carousel-image {
        height: 24em; } }
    @media (min-width: 62em) {
      .teaser div.carousel-image {
        height: 30em; } }
    @media (min-width: 120em) {
      .teaser div.carousel-image {
        height: 54em; } }
  @media (min-width: 62em) {
    .teaser .flickity-enabled .carousel-image {
      width: 60em; } }
  @media (min-width: 120em) {
    .teaser .flickity-enabled .carousel-image {
      width: 100em; } }
  .teaser .flickity-page-dots {
    z-index: 999;
    bottom: 0.75em; }
    .teaser .flickity-page-dots .dot {
      width: 12px;
      height: 12px;
      opacity: 1;
      background: transparent;
      border: 2px solid white; }
    .teaser .flickity-page-dots .dot.is-selected {
      background: white; }
  .teaser .flickity-prev-next-button {
    display: none;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.9);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    @media (min-width: 42em) {
      .teaser .flickity-prev-next-button {
        display: block; } }
    .teaser .flickity-prev-next-button:hover {
      border-color: #FFF; }
      .teaser .flickity-prev-next-button:hover .arrow {
        fill: #FFF; }
    .teaser .flickity-prev-next-button .arrow {
      fill: rgba(255, 255, 255, 0.9);
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }

.title {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-bottom: 0em;
  padding-left: 0%;
  padding-right: 0%; }
  @media (min-width: 42em) {
    .title {
      padding-left: 15%;
      padding-right: 15%; } }
  @media (min-width: 62em) {
    .title {
      padding-left: 30%;
      padding-right: 30%; } }
  @media (min-width: 120em) {
    .title {
      padding-left: 30%;
      padding-right: 30%; } }
  .title.title-long {
    background: linear-gradient(#D5B0AC 50%, transparent 50%); }
  .title.title-short {
    background: linear-gradient(#D5B0AC 50%, transparent 50%); }

.content {
  max-width: 60em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
  margin-left: 1em;
  margin-right: 1em; }
  .content:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 62em) {
    .content {
      margin-left: auto;
      margin-right: auto; } }
  .content .block,
  .content .text {
    clear: left; }
  .content .block-full {
    display: inline-block;
    width: 100%;
    background-color: #805753;
    position: relative;
    float: left;
    clear: both;
    color: white;
    padding: 20px;
    text-align: center; }
  .content.content-full .main-content {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .content.content-full .main-content .block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    @media (min-width: 42em) {
      .content.content-full .main-content .block {
        width: 32.20339%;
        float: left; }
        .content.content-full .main-content .block:nth-child(3n + 1) {
          margin-left: 0;
          margin-right: -100%;
          clear: both;
          margin-left: 0; }
        .content.content-full .main-content .block:nth-child(3n + 2) {
          margin-left: 33.89831%;
          margin-right: -100%;
          clear: none; }
        .content.content-full .main-content .block:nth-child(3n + 3) {
          margin-left: 67.79661%;
          margin-right: -100%;
          clear: none; } }
    @media (min-width: 62em) {
      .content.content-full .main-content .block {
        width: 32.20339%;
        float: left; }
        .content.content-full .main-content .block:nth-child(3n + 1) {
          margin-left: 0;
          margin-right: -100%;
          clear: both;
          margin-left: 0; }
        .content.content-full .main-content .block:nth-child(3n + 2) {
          margin-left: 33.89831%;
          margin-right: -100%;
          clear: none; }
        .content.content-full .main-content .block:nth-child(3n + 3) {
          margin-left: 67.79661%;
          margin-right: -100%;
          clear: none; } }
  .content.content-full .side-content {
    display: none; }
  .content.content-half .main-content {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .content.content-half .main-content .block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    @media (min-width: 42em) {
      .content.content-half .main-content {
        width: 66.10169%;
        float: left;
        margin-right: 1.69492%; }
        .content.content-half .main-content .block {
          width: 48.71795%;
          float: left; }
          .content.content-half .main-content .block:nth-child(2n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .content.content-half .main-content .block:nth-child(2n + 2) {
            margin-left: 51.28205%;
            margin-right: -100%;
            clear: none; } }
    @media (min-width: 62em) {
      .content.content-half .main-content {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%; }
        .content.content-half .main-content .block {
          width: 48.27586%;
          float: left; }
          .content.content-half .main-content .block:nth-child(2n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .content.content-half .main-content .block:nth-child(2n + 2) {
            margin-left: 51.72414%;
            margin-right: -100%;
            clear: none; } }
  .content.content-half .side-content {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .content.content-half .side-content .block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    @media (min-width: 42em) {
      .content.content-half .side-content {
        width: 32.20339%;
        float: right;
        margin-right: 0; }
        .content.content-half .side-content .block {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
    @media (min-width: 62em) {
      .content.content-half .side-content {
        width: 49.15254%;
        float: right;
        margin-right: 0; }
        .content.content-half .side-content .block {
          width: 48.27586%;
          float: left; }
          .content.content-half .side-content .block:nth-child(2n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .content.content-half .side-content .block:nth-child(2n + 2) {
            margin-left: 51.72414%;
            margin-right: -100%;
            clear: none; } }
  .content.content-fourth .main-content {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .content.content-fourth .main-content .block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .content.content-fourth .main-content .text {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    @media (min-width: 42em) {
      .content.content-fourth .main-content {
        width: 66.10169%;
        float: left;
        margin-right: 1.69492%; }
        .content.content-fourth .main-content .block {
          width: 48.71795%;
          float: left; }
          .content.content-fourth .main-content .block:nth-child(2n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .content.content-fourth .main-content .block:nth-child(2n + 2) {
            margin-left: 51.28205%;
            margin-right: -100%;
            clear: none; }
        .content.content-fourth .main-content .text {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
    @media (min-width: 62em) {
      .content.content-fourth .main-content {
        width: 74.57627%;
        float: left;
        margin-right: 1.69492%; }
        .content.content-fourth .main-content .block {
          width: 31.81818%;
          float: left; }
          .content.content-fourth .main-content .block:nth-child(3n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .content.content-fourth .main-content .block:nth-child(3n + 2) {
            margin-left: 34.09091%;
            margin-right: -100%;
            clear: none; }
          .content.content-fourth .main-content .block:nth-child(3n + 3) {
            margin-left: 68.18182%;
            margin-right: -100%;
            clear: none; }
        .content.content-fourth .main-content .text {
          width: 88.63636%;
          float: left;
          margin-right: 2.27273%; } }
  .content.content-fourth .side-content {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .content.content-fourth .side-content .block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    @media (min-width: 42em) {
      .content.content-fourth .side-content {
        width: 32.20339%;
        float: right;
        margin-right: 0; }
        .content.content-fourth .side-content .block {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
    @media (min-width: 62em) {
      .content.content-fourth .side-content {
        width: 23.72881%;
        float: right;
        margin-right: 0; }
        .content.content-fourth .side-content .block {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }

.footer {
  height: 39em;
  background-color: #402E2A; }
  @media (min-width: 42em) {
    .footer {
      height: 31.5em; } }
  @media (min-width: 62em) {
    .footer {
      height: 24em; } }
  .footer .footer-inner,
  .footer .footer-bottom {
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
    margin-left: 1em;
    margin-right: 1em; }
    .footer .footer-inner:after,
    .footer .footer-bottom:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 62em) {
      .footer .footer-inner,
      .footer .footer-bottom {
        margin-left: auto;
        margin-right: auto; } }
  .footer .footer-bottom hr {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    border-color: #805753;
    border-width: 2px; }
  .footer .footer-bottom .footer-bottom-navigation {
    float: left;
    margin-left: 0; }
    .footer .footer-bottom .footer-bottom-navigation li {
      list-style-type: none; }
    .footer .footer-bottom .footer-bottom-navigation a {
      color: rgba(255, 255, 255, 0.5); }
  .footer .footer-bottom .copyright {
    color: rgba(255, 255, 255, 0.5);
    float: right; }
  .footer .footer-inner {
    padding-top: 1.5em; }
    .footer .footer-inner address {
      font-size: 1em;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.5);
      width: 49.15254%;
      float: left;
      margin-bottom: 1.5em; }
      .footer .footer-inner address:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .footer .footer-inner address:nth-child(2n + 2) {
        margin-left: 50.84746%;
        margin-right: -100%;
        clear: none; }
      @media (min-width: 42em) {
        .footer .footer-inner address {
          width: 32.20339%;
          float: left; }
          .footer .footer-inner address:nth-child(3n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .footer .footer-inner address:nth-child(3n + 2) {
            margin-left: 33.89831%;
            margin-right: -100%;
            clear: none; }
          .footer .footer-inner address:nth-child(3n + 3) {
            margin-left: 67.79661%;
            margin-right: -100%;
            clear: none; } }
      @media (min-width: 62em) {
        .footer .footer-inner address {
          width: 23.72881%;
          float: left; }
          .footer .footer-inner address:nth-child(4n + 1) {
            margin-left: 0;
            margin-right: -100%;
            clear: both;
            margin-left: 0; }
          .footer .footer-inner address:nth-child(4n + 2) {
            margin-left: 25.42373%;
            margin-right: -100%;
            clear: none; }
          .footer .footer-inner address:nth-child(4n + 3) {
            margin-left: 50.84746%;
            margin-right: -100%;
            clear: none; }
          .footer .footer-inner address:nth-child(4n + 4) {
            margin-left: 76.27119%;
            margin-right: -100%;
            clear: none; } }
      .footer .footer-inner address a {
        color: rgba(255, 255, 255, 0.5);
        text-decoration: underline; }

.logo {
  margin-top: 0.75em;
  float: left;
  margin-left: 1em; }
  @media (min-width: 62em) {
    .logo {
      margin-left: 0em; } }
  .logo img {
    margin-top: 10px;
    height: 50px; }

.navigation {
  float: right;
  margin-top: 2.625em;
  margin-right: 1em; }
  @media (min-width: 42em) {
    .navigation {
      margin-top: 3.375em; } }
  @media (min-width: 62em) {
    .navigation {
      margin-right: 0em; } }
  .navigation ul {
    margin-bottom: 0; }
    @media (max-width: 42em) {
      .navigation ul {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(64, 46, 42, 0.9);
        margin: 0;
        padding-top: 10%; }
        .navigation ul.open {
          transition: opacity 0.5s, visibility 0.5s;
          opacity: 1;
          visibility: visible; }
        .navigation ul li {
          width: 100%;
          margin: 20px 0;
          text-align: center; }
          .navigation ul li.is-acitve {
            border-bottom: none !important; } }
    .navigation ul li {
      display: inline-block;
      margin-right: 2em; }
      .navigation ul li.is-acitve {
        border-bottom: solid 2px white; }
      .navigation ul li:last-child {
        margin-right: 0; }
      .navigation ul li a {
        font-family: 'walsheim-medium';
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        color: #FFF; }
  .navigation .navigation-toggle {
    float: right;
    display: none;
    z-index: 99999;
    position: relative;
    cursor: pointer;
    padding-top: 0.375em;
    height: 29px;
    width: 35px; }
    @media (max-width: 42em) {
      .navigation .navigation-toggle {
        display: block; } }
    .navigation .navigation-toggle span,
    .navigation .navigation-toggle span:before,
    .navigation .navigation-toggle span:after {
      cursor: pointer;
      height: 3px;
      width: 35px;
      background: white;
      position: absolute;
      display: block;
      content: '';
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), bottom 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -moz-transition: -moz-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), bottom 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), bottom 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .navigation .navigation-toggle span:before {
      top: -10px; }
    .navigation .navigation-toggle span:after {
      bottom: -10px; }
    .navigation .navigation-toggle.open span {
      background-color: transparent;
      width: 0; }
    .navigation .navigation-toggle.open span:before {
      top: 0; }
    .navigation .navigation-toggle.open span:after {
      bottom: 0; }
    .navigation .navigation-toggle.open span:before {
      transform: rotate(45deg); }
    .navigation .navigation-toggle.open span:after {
      transform: rotate(-45deg); }

.block {
  margin-bottom: 1.125em;
  padding: 0.75em;
  color: #FFF; }
  .block a {
    color: #FFF; }
  .block ul {
    margin-left: 0; }
  .block li {
    list-style-type: none; }
  .block.block-link, .block.block-news {
    position: relative;
    height: 12em;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: -moz-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    @media (min-width: 62em) {
      .block.block-link:hover, .block.block-news:hover {
        -webkit-transform: translateY(-0.375em) translateX(-0.375em);
        -moz-transform: translateY(-0.375em) translateX(-0.375em);
        -ms-transform: translateY(-0.375em) translateX(-0.375em);
        -o-transform: translateY(-0.375em) translateX(-0.375em);
        transform: translateY(-0.375em) translateX(-0.375em);
        box-shadow: 0.75em 0.75em 0px #402E2A; } }
  .block.block-side {
    background-color: #805753; }
    .block.block-side li {
      background-image: url("../img/icons/link.png");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0px 4px;
      padding-left: 1.4em; }
    .block.block-side a {
      border-bottom: solid 1px transparent;
      -webkit-transition: border-bottom 0.1s ease;
      -moz-transition: border-bottom 0.1s ease;
      transition: border-bottom 0.1s ease; }
      .block.block-side a:hover {
        border-bottom: solid 1px #FFF; }
    .block.block-side.block-side-lightbrown {
      background-color: #805753; }
    .block.block-side.block-side-violet {
      background-color: #684551; }
  .block.block-news {
    background-color: #684551; }
    .block.block-news h2 {
      margin-top: 1.125em;
      text-align: center; }
    .block.block-news h4 {
      text-align: center;
      position: relative; }
    .block.block-news.block-news-green {
      background-color: #A9DA6F; }
    .block.block-news.block-news-lightbrown {
      background-color: #805753; }
    .block.block-news.block-news-violet {
      background-color: #684551; }
  .block.block-link {
    overflow: hidden;
    background-color: #A9DA6F; }
    .block.block-link h2 {
      text-align: center;
      position: relative;
      margin-top: 0.375em; }
    .block.block-link img {
      position: relative;
      display: block;
      margin: 0 auto;
      height: 128px;
      width: 128px; }

.back {
  font-family: "walsheim-medium";
  color: #684551;
  display: block;
  height: 3em;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 1.5em;
  padding-top: 0.75em;
  background-color: #A9DA6F; }
  @media (min-width: 42em) {
    .back {
      position: relative;
      z-index: auto;
      width: auto;
      padding-left: 0;
      padding-top: 0;
      background-color: transparent;
      -webkit-transition: margin-left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -moz-transition: margin-left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: margin-left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .back:hover {
        margin-left: 10px; } }
  .back:before {
    content: " ";
    float: left;
    margin-top: -7px;
    margin-right: 10px;
    display: inline-block;
    width: 37px;
    height: 37px;
    background-image: url("/img/back@2x.png");
    background-size: 37px 37px;
    background-repeat: no-repeat; }

.tabs {
  position: relative;
  display: block;
  width: auto;
  float: left;
  background-color: #684551;
  height: 3em;
  padding: 0.1875em;
  margin: 0 0 3em; }
  .tabs a {
    color: #D5B0AC;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out; }
  .tabs .tabs__tab {
    position: relative;
    height: 2.625em;
    padding: 0.5625em 0.75em;
    list-style-type: none;
    display: inline-block;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out; }
    .tabs .tabs__tab:hover {
      background-color: rgba(213, 176, 172, 0.5); }
      .tabs .tabs__tab:hover a {
        color: #402E2A; }
    .tabs .tabs__tab.is-active {
      background-color: #D5B0AC; }
      .tabs .tabs__tab.is-active a {
        color: #402E2A; }

.tabs-content {
  visibility: hidden;
  opacity: 0;
  height: 0;
  display: none;
  -webkit-transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out; }
  .tabs-content.is-active {
    visibility: visible;
    opacity: 1;
    height: auto;
    display: block; }

.termin {
  border-bottom: solid 2px #D5B0AC;
  margin-bottom: 1.5em; }

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }
