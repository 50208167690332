.content {
  @include container;
  margin-bottom: emRhythm(2*4);

  margin-left: 1em;
  margin-right: 1em;

  @include breakpoint($screen) {
    margin-left: auto;
    margin-right: auto;
  }

  .block,
  .text { clear: left; }

  .block-full {
    display: inline-block;
    width: 100%;
    background-color: $lightbrown;
    position: relative;
    float: left;
    clear: both;
    color: white;
    padding: 20px;
    text-align: center;
  }

  &.content-full {
    .main-content {
      @include span(12 nest);
      .block { @include span(full); }

      @include breakpoint($tablet) {
        .block { @include gallery(4 of 12); }
      }

      @include breakpoint($screen) {
        .block { @include gallery(4 of 12); }
      }
    }
    .side-content { display: none; }

  } //&.content-full

 &.content-half {
    .main-content {
      @include span(12 nest);
      .block { @include span(12); }

      @include breakpoint($tablet) {
        @include span(8 nest);
        .block { @include gallery(4 of 8); }
      }

      @include breakpoint($screen) {
        @include span(6 nest);
        .block { @include gallery(3 of 6); }
      }
    }
    .side-content {
      @include span(12 nest);
      .block { @include span(12); }

      @include breakpoint($tablet) {
        @include span(4 nest last);
        .block { @include span(4 of 4); }
      }

      @include breakpoint($screen) {
        @include span(6 nest last);
        .block { @include gallery(3 of 6); }
      }
    }
  } //&.content-half

  &.content-fourth {
    .main-content {
      @include span(12 nest);
      .block { @include span(full); }
      .text { @include span(full); }

      @include breakpoint($tablet) {
        @include span(8 nest);
        .block { @include gallery(4 of 8); }
        .text { @include span(8 of 8); }
      }

      @include breakpoint($screen) {
        @include span(9 nest);
        .block { @include gallery(3 of 9); }
        .text { @include span(8 of 9); }
      }
    }
    .side-content {
      @include span(12 nest);
      .block { @include span(12); }

      @include breakpoint($tablet) {
        @include span(4 nest last);
        .block { @include span(4 of 4); }
      }

      @include breakpoint($screen) {
        @include span(3 nest last);
        .block { @include span(3 of 3); }
      }
    }
  } //&.content-fourth
} //.content
