.logo {
  margin-top: emRhythm(2);
  float: left;
  margin-left: 1em;
  @include breakpoint($screen) { margin-left: 0em; }

  img {
    // margin-top: 20px;
    // width: 64px;
    // height: 74px;

    margin-top: 10px;
    height: 50px;
  }
}
