.block {
  $colors: (
    green: $green,
    lightbrown: $lightbrown,
    violet: $violet
  );

  margin-bottom: emRhythm(1*3);
  padding: emRhythm(2);

  color: $white;

  a {
    color: $white;
    // text-decoration: underline;
  }

  ul { margin-left: 0; }
  li { list-style-type: none; }

  &.block-link,
  &.block-news {
    position: relative;
    height: emRhythm(8*4);

    @include transition(
      transform .3s $ease-out-back,
      box-shadow .3s $ease-out-back
    );

    @include breakpoint($screen) {
      &:hover {
        @include transform(
          translateY(emRhythm(-1))
          translateX(emRhythm(-1))
        );
        box-shadow: emRhythm(2) emRhythm(2) 0px $brown;
      }
    }
  }

  &.block-side {
    background-color: $lightbrown;

    li {
      background-image: url("../img/icons/link.png");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 0px 4px;
      padding-left: 1.4em;
    }

    a {
      border-bottom: solid 1px rgba(0,0,0,0);

      @include transition(
        border-bottom .1s ease
      );

      &:hover {
        border-bottom: solid 1px $white;
      }
    }

    @each $color in lightbrown, violet {
      &.block-side-#{$color} {
        background-color: map-get($colors, $color);
      }
    }
  }

  &.block-news {
    background-color: $violet;

    h2 {
      margin-top: emRhythm(1*3);
      text-align: center;
    }

    h4 {
      text-align: center;
      position: relative;
    }

    h4 {
      // position: absolute;
      // margin-bottom: 0;
      // display: block;
      // bottom: emRhythm(2);
      // border-bottom: solid 2px $white;
    }

    @each $color in green, lightbrown, violet {
      &.block-news-#{$color} {
        background-color: map-get($colors, $color);
      }
    }
  }

  &.block-link {
    overflow: hidden;
    background-color: $green;

    h2 {
      text-align: center;
      position: relative;
      margin-top: emRhythm(1);
    }

    img {
      position: relative;
      display: block;
      margin: 0 auto;
      // height: 55%;
      height: 128px;
      width: 128px;
    }
  } //&.block-link
} //.block
