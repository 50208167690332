.teaser {
  $height-low: emRhythm(4*16);
  $height: emRhythm(4*16);
  $height-high: emRhythm(5*16);
  $height-superHigh: emRhythm(9*16);

  position: relative;
  width: 100%;
  height: $height-low;
  background-color: $violet;
  overflow: hidden;

  @include breakpoint($tablet) {
    height: $height;
  }
  @include breakpoint($screen) {
    height: $height-high;
  }
  @include breakpoint($wide) {
    height: $height-superHigh;
  }



  div.carousel-image {
    transition: opacity 0.4s;
    opacity: 0;

    &.flickity-bg-lazyloaded,
    &.flickity-bg-lazyerror {
      opacity: 1;
    }

    margin: 0 auto;
    width: 100%;
    height: $height;

    overflow: hidden;
    background-size: cover;
    background-position: center center;

    @include breakpoint($tablet) {
      height: $height;
    }
    @include breakpoint($screen) {
      height: $height-high;
    }
    @include breakpoint($wide) {
      height: $height-superHigh;
    }
  }

  .flickity-enabled {
    .carousel-image {
      @include breakpoint($screen) {
        width: 60em;
      }
      @include breakpoint($wide) {
        width: 100em;
      }
    }
  }

  .flickity-page-dots {
    z-index: 999;
    bottom: (emRhythm(1*2));

    .dot {
      width: 12px;
      height: 12px;
      opacity: 1;
      background: transparent;
      border: 2px solid white;
    }

    .dot.is-selected {
      background: white;
    }
  }

  .flickity-prev-next-button {
    display: none;

    @include breakpoint($tablet) {
      display: block;
    }

    background: transparent;
    border: 2px solid rgba($white, 0.9);

    @include transition(
      all 0.2s ease-in-out
    );

    &:hover {
      border-color: $white;

      .arrow {
        fill: $white;
      }
    }

    &:disabled {
    }

    .arrow {
      fill: rgba($white, 0.9);

      @include transition(
        all 0.2s ease-in-out
      );
    }
  }
}
