$green: #A9DA6F;
$brown: #402E2A;
$lightbrown: #805753;
$violet: #684551;
$lightviolet: #D5B0AC;
$white: #FFF;
$lightgray: #F7F7F7;
$black: #000;

$mobile: max-width 42em;
$tablet: 42em;
$screen: 62em;
$wide: 120em;

$font-sans: 'walsheim-light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-serif: 'walsheim-regular', 'Georgia', 'Times New Roman', serif;
$font-mono: 'Lucida Console', Monaco, monospace;
$text-color: #000;
$base-vertical-unit: 6px;
$base-line-multi: 4;
$base-font-size: 16px;
$ms-ratio: 1.414;
$paragraph-indent: true;
$paragraph-justify: false;
$load-typesetted: true;

$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after,
  container: 60em,
  container-position: center,
  columns: 12,
  gutters: .25,
  // gutters: 0.5,
  column-width: false,
  global-box-sizing: border-box,
  last-flow: to,
  debug: (
    image: hide,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  )
);
