.back {
  font-family: "walsheim-medium";
  color: $violet;

  display: block;
  height: emRhythm(2*4);

  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: emRhythm(1*4);
  padding-top: emRhythm(1*2);
  background-color: $green;

  @include breakpoint($tablet) {
    position: relative;
    z-index: auto;
    width: auto;
    padding-left: 0;
    padding-top: 0;
    background-color: transparent;

    @include transition(
      margin-left .2s $ease-out-back
    );

    &:hover {
      margin-left: 10px;
    }
  }

  &:before {
    content: " ";
    float: left;
    margin-top: -7px;
    margin-right: 10px;
    display: inline-block;
    width: 37px;
    height: 37px;
    background-image: url("/img/back@2x.png");
    background-size: 37px 37px;
    background-repeat: no-repeat;
  }
}
