body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-smooth: always !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;

  // Use ligatures if the typeface and font file include them
  // @include font-feature-settings("liga");

  // Use proportional numbers, but not automatic kerning
  // @include font-feature-settings("pnum", "kern" false);

  font-style: normal;
  font-weight: normal;
}

::selection {
  background: $green;
  color: $brown;
 -webkit-text-stroke-width: 0;
}
::-moz-selection {
  background: $green;
  color: $brown;
}

h1 {
  @include setType(6, $ms-up2);
  color: $violet;
  font-family: "walsheim-bold";
  text-transform: uppercase;

  @include breakpoint($tablet) {
    @include setType(8, $ms-up3);
  }

  @include breakpoint($screen) {
    @include setType(3*4, $ms-up4);

    @include transform(rotate(-3deg));

    // letter-spacing: 2px;
    // color: rgba(0,0,0,0);
    // -webkit-text-stroke-width: 2px;
    // -webkit-text-stroke-color: $violet;

    // letter-spacing: 2px;
    // color: $lightviolet;
    // text-shadow:
    //   -2px -2px 0 $violet,
    //   2px -2px 0 $violet,
    //   -2px 2px 0 $violet,
    //   2px 2px 0 $violet;

    color: $violet;
  }
}

h2 {
  font-family: "walsheim-light";
  @include setType(1*5, $ms-up1);
  // text-transform: uppercase;
  color: $white;
  margin-bottom: emRhythm(2);
  letter-spacing: 0px;
}

h3 {
  font-family: "walsheim-regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  @include setType(1*4, 1em);
  margin-bottom: 0;
  // color: $green;
}

h4 {
  font-family: "walsheim-regular";
  @include setType(1*4, 1em);
}

a {
  text-decoration: none;
  color: white;
}

p {
  // margin-bottom: 0;
  hyphens: none;
}

.text a {
  text-decoration: underline;
  color: $black;
}
